import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initial:any = [];

export const summaryListSlice = createSlice({
    name: "UpdateCounterAction",
    initialState:initial,
    reducers: {
        setSummaryList: (state: any, actions: PayloadAction<any>) => {
            return actions.payload?actions.payload :[]
        },
    },
});

export const { setSummaryList } = summaryListSlice.actions
export default summaryListSlice.reducer